import React from "react"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"
import {FormattedMessage} from "react-intl";

class BodyClass extends React.Component {
  static propTypes = {
    name: React.PropTypes
  }
  static defaultProps = {
    name: ''
  }
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}
class TimelineAddCar extends React.Component {
  content() {
    return(
      <div className="col-lg-8">
        <div className="white-container">
          <div className="text-center pt-4 pb-3">

            <span className="icon icon-car icon-car-new light-grey-border fs-50"></span>

            <h2 className="mt-4"><FormattedMessage id="timeline.notice_box.2.title"/></h2>

            <p><FormattedMessage id="timeline.notice_box.2.description"/></p>

            <Link to="/mycars/add-car" className="button red mx-auto with-icon"><span className="icon-car icon-car-new icon"></span><span><FormattedMessage id="timeline.notice_box.2.button"/></span></Link>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return(
      <Layout>
        <SEO title="Add a Car" />
        <BodyClass name="large-wrapper" />

        <MediaLayout children={this.content()} currentPage="timeline" />

        <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

      </Layout>
    )
  }
}export default TimelineAddCar
